<template>
  <v-app-bar
    app
    class="appbarssc"
    clipped-left
    :class="classCordova"
    elevation="0"
  >
    <v-row no-gutters :align="'center'">
      <v-col cols="2" xl="1" class="justify-start align-center">
        <v-btn
          aria-label="Apri menu istituzionale"
          icon
          tile
          class="no-border rounded"
          style="right: -5px"
          @click.prevent.stop="drawerLeft = !drawerLeft"
        >
          <v-icon color="white">$menu</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="8" xl="10" class="d-flex justify-center"
        ><img
          class="px-0"
          src="/logo/logo-small-white.png"
          alt="Logo"
          :style="{ height: '48px' }"
      /></v-col>
      <v-col cols="2" xl="1" class="d-flex justify-end">
        <v-btn
          v-if="$route.name === 'CartRecap'"
          icon
          tile
          exact
          class="no-border rounded"
          :to="{ name: 'HomeSSC' }"
        >
          <v-icon color="white">$sort</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          tile
          class="no-border rounded"
          :to="{ name: 'CartRecap' }"
          :disabled="!initCode"
        >
          <v-icon color="white" large>$cart</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-progress-linear
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>
<style lang="scss">
.appbarssc.v-app-bar--fixed {
  height: fit-content !important;
  background-color: $primary !important;
  .v-btn.v-btn--disabled .v-icon {
    color: #ffffff42 !important;
  }
}
</style>
<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "NavbarSSC",
  mixins: [],
  components: {},
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft"
    })
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft
    }),
    ...mapGetters({
      cartSSC: "custom/getCartSSC"
    }),
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    initCode() {
      return Object.keys(this.cartSSC).length != 0;
    }
  }
};
</script>

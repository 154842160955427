<template>
  <v-card class="ssc-popup-card">
    <v-card-title v-if="popup.title" class="popup-title">{{
      popup.title
    }}</v-card-title>
    <v-card-text
      ><v-row
        v-for="list in popup.lists"
        :key="list.id"
        outlined
        :list="list"
        @click="selectList(list)"
      >
        <v-col
          ><v-badge
            color="primary"
            inline
            :content="list.size"
            :value="list.size > 0"
          >
            {{ list.name }}
          </v-badge></v-col
        >
      </v-row></v-card-text
    >
    <v-card-actions class="justify-end">
      <v-btn color="primary" depressed min-width="150" @click="submit()">{{
        $t("selfScanning.cancleListImport")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss">
.ssc-popup-card {
  .popup-title {
    font-size: 20px;
  }
}
</style>

<script>
import SelfscanningService from "~/service/selfscanningService";
import { mapActions } from "vuex";

export default {
  name: "SelfscanningListSelector",
  props: {
    popup: { type: Object, required: true }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      updateCartSSC: "custom/updateCartSSC"
    }),
    submit() {
      this.$emit("submit", true);
    },
    async selectList(list) {
      let cloneList = await SelfscanningService.cloneFromList(list.id);
      this.$emit("submit", true);
      if (cloneList) {
        //global.EventBus.$emit("updateSelfCart", cloneList.data);
        this.updateCartSSC(cloneList.data.data);
      }
    }
  }
};
</script>

<template>
  <v-card class="popup-card promo-input-popup">
    <v-card-title
      v-if="popup.title"
      class="popup-title justify-center error--text text-uppercase"
      >{{ popup.title }}<span class="title-box"></span
      ><span class="subtitle-box"></span
    ></v-card-title>
    <v-card-title class="popup-title justify-center"
      >{{ popup.type == "promo" ? "Passata in bottiglia" : "SHAMPOO CAMOMILLA"
      }}<span class="title-box"></span><span class="subtitle-box"></span
    ></v-card-title>
    <v-card-title class="popup-title justify-center"
      ><span
        v-html="
          popup.type == 'promo'
            ? `<span class='text-decoration-line-through'>€3,09</span><span><b class='error--text'> €1,99</b></span>`
            : '<span>€6.59</span>'
        "
      ></span
    ></v-card-title>
    <div class="d-flex justify-center">
      <img
        onerror="this.onerror=null;this.src='/no-icon.png'"
        :src="
          popup.type == 'promo'
            ? 'img_layout/passata_small.png'
            : 'img_layout/shampoo.jpg'
        "
        :alt="promoProduct.name"
        width="200"
      />
    </div>
    <v-card-actions class="justify-space-between">
      <v-btn color="primary" class="action-btn" depressed @click="submit()">{{
        $t("selfScanning.promoProduct.cancel")
      }}</v-btn>
      <v-btn
        color="secondary"
        class="action-btn"
        depressed
        @click="addCode()"
        >{{ $t("selfScanning.promoProduct.confirm") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import ProductService from "@/commons/service/productService";
import ListService from "@/commons/service/listService";

export default {
  name: "PromoProduct",
  props: {
    popup: { type: Object, required: true }
  },
  data() {
    return {
      promoProduct: {}
    };
  },
  methods: {
    submit() {
      this.$emit("submit", false);
    },
    async addCode() {
      console.log("ADD");
      ListService.addProductToList(
        this.promoProduct,
        null,
        this.popup.quantity
      );
      this.$emit("submit", false);
    }
  },
  mounted() {
    console.log("PRODUCT", this.popup.product);
    ProductService.getProductBySlug(this.popup.product).then(data => {
      this.promoProduct = data;
    });
  }
};
</script>
<style lang="scss">
.promo-input-popup {
  background-color: var(--v-grey-lighten3) !important;
  .action-btn {
    flex: 0 0 calc(50% - 5px);
    .v-btn__content {
      flex-shrink: 1;
      white-space: pre-wrap;
    }
  }
}
</style>

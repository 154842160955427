// import { handleCategoryNavigation } from "./routeFunctions";
import base from "./base";
import wordpress from "./wordpress";
import selfscanning from "./selfscanning";
import catalog from "./catalog";
import profile from "./profile";
import checkout from "./checkout";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      site: "ecommerce",
      slug: "home-walterscheid"
    },
    component: () => import("@/views/Home.vue")
  },
  ...base,
  ...wordpress,
  ...profile,
  ...checkout,
  ...selfscanning,
  ...catalog
];

export default routes;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"delivery-popup"},[_c('div',{staticClass:"date-time"},[(!_vm.selectAdress)?_c('div',{staticClass:"date-selector"},[_c('v-tabs',{staticClass:"days-tabs",attrs:{"hide-slider":"","icons-and-text":"","center-active":"","slider-size":"1","show-arrows":"","fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_vm._l((_vm.days),function(timeslotDay){return _c('v-tab',{key:timeslotDay.dateIso,on:{"click":function($event){return _vm.selectTimeslotDay(timeslotDay)}}},[_c('div',{staticClass:"time-div d-flex flex-column align-center justify-space-around"},[(_vm.$dayjs().isSame(timeslotDay.dateIso, 'day'))?[_c('span',{staticClass:"day-number font-weight-bold"},[_vm._v(" OGGI ")])]:[_c('span',{staticClass:"day-string font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$dayjs(timeslotDay.dateIso).format("dddd"))+" ")]),_c('span',{staticClass:"day-number font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$dayjs(timeslotDay.dateIso).format("DD/MM"))+" ")])]],2)])})],2),_c('div',{staticClass:"text-center py-2 text-body-0 primary--text font-weight-bold"},[_vm._v(" Seleziona quando ritirare la tua spesa ")]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.days),function(timeslotDay){return _c('v-tab-item',{key:timeslotDay.dateIso,staticClass:"timeslot-container"},[(timeslotDay.timeslots.length > 0)?_c('v-row',{attrs:{"dense":"","no-gutters":""}},_vm._l((timeslotDay.timeslots),function(timeslot){return _c('v-col',{key:timeslot.timeslotId,attrs:{"cols":"4","sm":"3","md":"2","lg":"3"}},[_c('v-card',{staticClass:"timeslot-card",attrs:{"flat":""}},[_c('div',{staticClass:"timeslot-button d-flex justify-center",class:[
                    timeslot.status,
                    { 'under-lead-time': timeslot.underLeadTime },
                    { 'under-lock-day': timeslot.underLockDay },
                    { 'under-lock-cutoff': timeslot.underLockCutoff }
                  ],on:{"click":function($event){return _vm.selectTimeslot(timeslot)}}},[_c('span',{staticClass:"lock-icons"},[(
                        timeslot.underLeadTime ||
                          timeslot.underLockDay ||
                          timeslot.underLockCutoff
                      )?_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" $clock ")]):_vm._e()],1),_c('span',{staticClass:"timeslot-time"},[_vm._v(_vm._s(timeslot.beginTime)+" - "+_vm._s(timeslot.endTime))])])])],1)}),1):_c('p',[_c('span',{staticClass:"d-block pa-3 secondary text-uppercase font-weight-bold text-center"},[_vm._v(_vm._s(_vm.$t("timeslots.noSlots")))]),_c('br'),_c('span',{staticClass:"d-block pa-3 error--text secondary text-uppercase font-weight-bold text-center"},[_vm._v(_vm._s(_vm.$t("timeslots.noSlotsSuggestion")))])])],1)}),1)],1):_c('div',{staticClass:"select-address"},[_c('div',{staticClass:"section-title"},[_vm._v("Seleziona un indirizzo per la consegna")]),_vm._l((_vm.addresses),function(address){return _c('v-list-item',{key:address.addressId,staticClass:"px-2 adress-list",on:{"click":function($event){return _vm.setAddress(address)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text font-weight-bold text-caption mb-1"},[_vm._v(" "+_vm._s(address.addressName)+" ")]),_c('v-list-item-subtitle',{staticClass:"primary--text text-caption text-sm-body-2"},[_vm._v(" "+_vm._s(address.address1)+" "+_vm._s(address.addressNumber)+" "+_vm._s(address.city)+" "+_vm._s(address.city)+" "+_vm._s(address.province)+" ")])],1)],1)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
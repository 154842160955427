<template>
  <v-card class="popup-card selfscanning-manual-input-popup">
    <v-card-title v-if="popup.title" class="popup-title"
      >{{ popup.title }}<span class="title-box"></span
      ><span class="subtitle-box"></span
    ></v-card-title>
    <v-card-text v-if="popup.type === 'remove'">
      <v-text-field required v-model="inputData.removeCode"></v-text-field>
    </v-card-text>
    <v-card-text v-if="popup.type === 'init'">
      <v-text-field required v-model="inputData.initCode"></v-text-field>
    </v-card-text>
    <v-card-text v-else>
      <v-text-field
        required
        v-model="inputData.itemCode"
        v-if="inputData.step == 1"
      ></v-text-field>
      <v-text-field
        required
        v-model="inputData.itemQuantity"
        v-if="inputData.step == 2"
      ></v-text-field
      ><span>{{ $n(inputData.product, "ssuffix") }}</span>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn color="primary" depressed min-width="150" @click="submit()">{{
        $t("selfScanning.manualInput.cancel")
      }}</v-btn>
      <v-btn
        v-if="popup.type === 'remove'"
        color="primary"
        depressed
        min-width="150"
        @click="removeItemByCode()"
        >{{ $t("selfScanning.manualInput.confirm") }}</v-btn
      >
      <v-btn
        v-if="popup.type === 'init'"
        color="primary"
        depressed
        min-width="150"
        @click="init()"
        >{{ $t("selfScanning.manualInput.confirm") }}</v-btn
      >
      <v-btn
        v-else
        color="primary"
        depressed
        min-width="150"
        @click="addCode()"
        >{{ $t("selfScanning.manualInput.confirm") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import SelfscanningService from "~/service/selfscanningService";

export default {
  name: "ManualInput",
  props: {
    popup: { type: Object, required: true }
  },
  data() {
    return {
      inputData: {
        step: 1,
        itemCode: null,
        error: null,
        removeCode: null
      }
    };
  },
  methods: {
    submit() {
      this.$emit("submit", false);
    },
    async removeItemByCode() {
      // this.handleScan(
      //   _this2.inputData.removeCode,
      //   "remove",
      //   function() {
      //     _this2.$emit("submit", true);
      //   },
      //   function() {
      //     this.inputData.error =
      //       "Errore nella rimozione dell'articolo dal carrello";
      //   }
      // );
    },
    async addCode() {
      var _this = this;
      var reCode = /^[0-9]+$/i;
      //var reQuantity = /^[0-9]+([,.][0-9]+)?$/i;
      this.inputData.error = null;
      if (!_this.inputData.itemCode) {
        _this.inputData.error = this.$t(
          "selfScanning.manualInput.enterItemCode"
        );
      } else if (!reCode.test(_this.inputData.itemCode)) {
        _this.inputData.error = this.$t(
          "selfScanning.manualInput.incorrectCodeFormat"
        );
      } else if (_this.inputData.step == 1) {
        let data = await SelfscanningService.checkCode(
          _this.inputData.itemCode
        );
        if (data && data.response.status == 0) {
          //_this.inputData.step = 2;
          _this.inputData.product = data.data;
          this.$emit("submit", {
            code: this.inputData.itemCode,
            q: 1,
            action: "add"
          });
          // var p = document.getElementsByClassName(
          //   "selfscanning-manual-input-popup"
          // )[0];
          // p.getElementsByClassName("title-box")[0].innerText =
          //   "Inserisci peso/quantità per:";
          // p.getElementsByClassName("subtitle-box")[0].innerText =
          //   data.data.name;
        } else {
          _this.inputData.error = this.$t(
            "selfScanning.manualInput.unrecognizedItemCode"
          );
        }
        // } else if (!_this.inputData.itemQuantity) {
        //   _this.inputData.error = "Inserire il valore richiesto";
        // } else if (!reQuantity.test(_this.inputData.itemQuantity)) {
        //   _this.inputData.error = "Formato valore non corretto";
      } else {
        this.$emit("submit", {
          code: this.inputData.itemCode,
          q: this.inputData.itemQuantity.replace(",", "."),
          action: "add"
        });
        // _this.handleScan(
        //   "c" + _this.inputData.itemCode,
        //   "add",
        //   function() {
        //     _this.$emit("submit", true);
        //   },
        //   function() {
        //     _this.inputData.error =
        //       "Errore nell'aggiunta dell'articolo al carrello";
        //   },
        //   _this.inputData.itemQuantity.replace(",", ".")
        // );
      }
    },
    init() {
      this.$emit("submit", {
        code: this.inputData.initCode,
        q: null,
        action: "init"
      });
    }
  }
};
</script>

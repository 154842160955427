import store from "@/store";
let routes = [
  {
    path: "/reset-password-request",
    name: "ResetPasswordRequest",
    component: () => import("@/views/profile/ResetPasswordRequest.vue"),
    meta: {
      slug: "reset-password-request"
    }
  },
  {
    path: "/reset-password-confirm",
    name: "ResetPasswordConfirm",
    props: route => ({
      message: route.params.message,
      login: route.params.login || route.query?.login,
      queryToken: route.query?.token
    }),
    meta: { slug: "reset-password-confirm" },
    component: () => import("@/views/profile/ResetPasswordConfirm.vue")
  },
  {
    path: "/reset-password-sent",
    name: "ResetPasswordSent",
    props: { showLogin: true },
    meta: { slug: "reset-password-sent" },
    component: () => import("@/views/EbsnCategory.vue")
  },
  {
    path: "/recover-password-confirm",
    name: "ResetPassword",
    component: () => import("@/views/profile/ResetPassword.vue"),
    props: { showLogin: true },
    meta: { slug: "reset-password" }
  },
  {
    path: "/reset-password-completed",
    name: "ResetPasswordCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    props: { showLogin: true },
    meta: { slug: "reset-password-completed" }
  },
  {
    path: "/registration-user",
    name: "RegistrationUser",
    component: () => import("@/views/profile/RegistrationUser.vue"),
    props: { showLogin: true },
    meta: { slug: "registration-choose" }
  },
  {
    path: "/registration-card",
    name: "RegistrationCard",
    component: () => import("@/views/profile/RegistrationForms.vue"),
    props: {
      type: "OldUser",
      isRegisterWithCard: true
    },
    meta: { slug: "registration-card" }
  },
  {
    path: "/registration-nocard",
    name: "RegistrationNoCard",
    component: () => import("@/views/profile/RegistrationForms.vue"),
    props: {
      type: "NewUser",
      isRegisterWithCard: false
    },
    meta: { slug: "registration-nocard" }
  },
  {
    path: "/thankyou-agreement",
    name: "RegistrationCompletedAgreement",
    component: () => import("@/views/profile/RegistrationCompleted.vue"),
    props: {
      mail: null,
      agreement: true
    },
    meta: { slug: "registration-confirm-agreement" }
  },
  {
    path: "/registration/confirm/:status",
    name: "RegistrationConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    props: {
      mail: null,
      agreement: true
    },
    meta: { slug: ["registration/confirm", "registration-confirm"] }
  },
  {
    path: "/registration/thankyou",
    name: "RegistrationCompleted",
    component: () => import("@/views/profile/RegistrationCompleted.vue"),
    props: {
      mail: null,
      agreement: true
    },
    meta: { slug: ["registration/thankyou", "registration-confirm"] }
  },
  {
    path: "/registration/ticket-update",
    name: "TicketUpdate",
    props: {
      ticketType: "buonipasto"
    },
    meta: {
      requiresAuth: true,
      breadCrumb: "Buoni pasto",
      slug: ["registration/ticket-update"]
    },

    component: () => import("@/views/profile/TicketUpdate.vue")
  },
  {
    path: "/registration/ticket-update-multiuso",
    name: "TicketMultiuso",
    props: {
      ticketType: "multiuso"
    },
    meta: {
      requiresAuth: true,
      breadCrumb: "Buoni multiuso",
      slug: ["registration/ticket-update-multiuso"]
    },

    component: () => import("@/views/profile/TicketUpdate.vue")
  },
  {
    path: "/registration-social-completed",
    name: "RegistrationSocialCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    meta: { slug: "registration-social-completed" }
  },
  {
    path: "/registration/card-agreement",
    name: "CardAgreement",
    component: () => import("@/views/profile/CardAgreement.vue"),
    meta: { slug: ["registration/card-agreement", "card-agreement"] }
  },
  {
    path: "/login-oro",
    name: "LoginOro",
    component: () => import("@/views/profile/LoginOro.vue"),
    meta: { slug: "login-oro" }
  },
  {
    path: "/profile",
    meta: {
      requiresAuth: true,
      breadCrumb: "Profile",
      hideDashboardMenu: true
    },
    component: () => import("@/views/profile/Profile.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        meta: {
          requiresAuth: true,
          showTcoMenu: false,
          slug: "dashboard",
          hideDashboardMenu: true
        },
        component: () => import("@/views/profile/Dashboard.vue")
      },
      {
        path: "profile-summary",
        name: "ProfileSummary",
        meta: {
          requiresAuth: true,
          breadCrumb: "I tuoi dati",
          showTcoMenu: true,
          slug: "profile"
        },
        component: () => import("@/views/profile/ProfileSummary.vue")
      },
      {
        path: "tigros-card",
        name: "TigrosCard",
        meta: {
          requiresAuth: true,
          breadCrumb: "Tigros Card",
          showTcoMenu: false
        },
        component: () => import("@/views/profile/TigrosCard.vue")
      },
      {
        path: "/card-oro/perks",
        name: "TcoPerks",
        meta: {
          requiresAuth: true,
          breadCrumb: "Card Oro",
          showTcoMenu: true,
          slug: ["profile/card-oro/perks", "perks"]
        },
        component: () => import("@/views/profile/cardOro/TcoPerks.vue")
      },
      {
        path: "/card-oro/rules",
        name: "TcoRules",
        meta: {
          requiresAuth: true,
          breadCrumb: "Card Oro",
          showTcoMenu: true,
          slug: ["profile/card-oro/card-oro-rules", "card-oro-rules"]
        },
        component: () => import("@/views/profile/cardOro/Rules.vue")
      },
      {
        path: "/card-oro/choose-product",
        name: "TcoChooseProduct",
        meta: { requiresAuth: true, breadCrumb: "Card Oro", showTcoMenu: true },
        component: () => import("@/views/profile/cardOro/TcoChooseProduct.vue")
      },
      {
        path: "/card-oro/become-tco",
        name: "BecomeTco",
        meta: {
          requiresAuth: true,
          breadCrumb: "Diventa Utente Oro"
        },
        component: () => import("@/views/profile/cardOro/BecomeTco.vue")
      },
      {
        path: "/card-oro/welcome-phisical-tco",
        name: "WelcomePhisicalTco",
        meta: { requiresAuth: true, breadCrumb: "Bevenuto", showTcoMenu: true },
        component: () =>
          import("@/views/profile/cardOro/WelcomePhisicalTco.vue")
      },
      {
        path: "/card-oro/welcome-digital-tco",
        name: "WelcomeDigitalTco",
        meta: { requiresAuth: true, breadCrumb: "Bevenuto", showTcoMenu: true },
        component: () => import("@/views/profile/cardOro/WelcomeDigitalTco.vue")
      },
      {
        path: "profile-update",
        name: "ProfileUpdate",
        props: route => ({
          emailchanged: route.query.emailchanged
        }),
        meta: {
          requiresAuth: true,
          breadCrumb: "I tuoi dati"
        },
        component: () => import("@/views/profile/ProfileUpdate.vue")
      },
      {
        path: "profile-update-error",
        name: "ProfileUpdateError",
        meta: {
          requiresAuth: true,
          breadCrumb: "I tuoi dati",
          slug: ["profile/profile-update-error", "profile-error"]
        },
        component: () => import("@/views/profile/ProfileUpdateError.vue")
      },
      {
        path: "changepassword",
        name: "UpdatePassword",
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["profile/changepassword", "change-password"]
        },
        component: () => import("@/views/profile/UpdatePassword.vue")
      },
      {
        path: "profile-confirmed",
        name: "ProfileConfirmed",
        component: () => import("@/views/profile/ProfileConfirmed.vue"),
        meta: { slug: ["profile/profile-confirmed", "profile-confirmed"] }
      },
      {
        path: "profile-updated",
        name: "ProfileUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: { slug: ["profile/profile-updated", "profile-updated"] }
      },
      {
        path: "email-updated",
        name: "EmailUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: { slug: ["profile/email-updated", "email-updated"] }
      },
      {
        path: "invite",
        name: "Invite",
        meta: {
          requiresAuth: true,
          breadCrumb: "Invita i tuoi amici",
          slug: ["profile/invite", "invite"]
        },
        component: () => import("@/views/profile/Invite.vue")
      },
      {
        path: "news",
        name: "NewsListPage",
        meta: {
          requiresAuth: true,
          breadCrumb: "Archivio Messaggi",
          slug: ["profile/news", "news-list"]
        },
        component: () => import("@/views/profile/NewsList.vue")
      },
      {
        path: "ticket",
        name: "Ticket",
        meta: {
          requiresAuth: true,
          breadCrumb: "Servizio clienti",
          slug: ["profile/ticket", "ticket"]
        },
        component: () => import("@/views/profile/Ticket.vue")
      },
      {
        path: "ticket-sent",
        name: "TicketSentSuccessfull",
        meta: {
          requiresAuth: true,
          breadCrumb: "Servizio clienti",
          slug: ["profile/ticket-sent", "ticket-sent"]
        },
        component: () => import("@/views/profile/TicketSentSuccessfull.vue")
      },
      {
        path: "ratings",
        name: "Ratings",
        meta: {
          requiresAuth: true,
          breadCrumb: "Prodotti da votare",
          slug: ["profile/ratings", "ratings"]
        },
        component: () => import("@/views/profile/Ratings.vue")
      },
      {
        path: "orders",
        meta: {
          requiresAuth: true,
          breadCrumb: "I tuoi ordini"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Orders",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["profile/orders", "orders"]
            },
            component: () => import("@/views/profile/Orders.vue")
          },
          {
            name: "Order",
            path: ":orderId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine",
              slug: ["profile/orders/order", "order"]
            },
            component: () => import("@/views/profile/Order.vue")
          }
        ]
      },
      {
        path: "modify",
        meta: {
          requiresAuth: true,
          breadCrumb: "Modifica ordini"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Modify",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["profile/modify", "modify"]
              // hideDashboardMenu: true
            },
            component: () => import("@/views/profile/Modify.vue")
          },
          {
            name: "ModifyOrder",
            path: ":orderId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Modifica ordine",
              slug: ["profile/modify/modify-order", "modify-order"],
              hideDashboardMenu: true
            },
            component: () => import("@/views/profile/ModifyOrder.vue")
          }
        ]
      },
      {
        path: "receipts",
        meta: {
          requiresAuth: true,
          breadCrumb: "Garanzie e bollette",
          showTcoMenu: true
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Receipts",
            path: "",
            props: { digital: false },
            meta: {
              requiresAuth: true,
              showTcoMenu: true,
              slug: ["profile/receipts", "receipts"]
            },
            component: () => import("@/views/profile/Receipts.vue")
          },
          {
            name: "Receipt",
            path: ":receiptId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio scontrino",
              showTcoMenu: true,
              slug: ["profile/receipts/receipt", "receipt"]
            },
            props: route => ({
              bill: route.query.bill,
              warranty: route.query.warranty,
              paymentType: route.query.paymentType
            }),
            component: () => import("@/views/profile/Receipt.vue")
          }
        ]
      },
      {
        path: "digital-receipts",
        meta: {
          requiresAuth: true,
          breadCrumb: "I tuoi scontrini",
          showTcoMenu: true
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "DigitalReceipts",
            path: "",
            props: { digital: true },
            meta: {
              requiresAuth: true,
              showTcoMenu: true,
              slug: "profile/digital-receipts"
            },
            component: () => import("@/views/profile/Receipts.vue")
          },
          {
            name: "DigitalReceipt",
            path: ":receiptId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio scontrino",
              showTcoMenu: true,
              slug: "profile/digital-receipts/digital-receipt"
            },
            props: route => ({
              bill: route.query.bill,
              warranty: route.query.warranty,
              paymentType: route.query.paymentType,
              digital: true
            }),
            component: () => import("@/views/profile/Receipt.vue")
          }
        ]
      },
      {
        path: "lists",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le tue liste",
          hideDashboardMenu: false
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Lists",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["profile/lists", "lists"],
              hideDashboardMenu: true
            },

            component: () => import("@/views/profile/Lists.vue")
          },
          {
            path: ":listId",
            name: "List",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio lista",
              hideDashboardMenu: true
            },
            component: () => import("@/views/profile/Lists.vue")
          }
        ]
      },
      {
        path: "preferred",
        name: "Favourites",
        meta: {
          requiresAuth: true,
          breadCrumb:
            store && store.getters["cart/hasFavorites"]
              ? "I miei preferiti"
              : "I più acquistati"
        },
        component: () => import("@/views/profile/Favourites.vue")
      },
      {
        path: "addresses",
        meta: { requiresAuth: true, breadCrumb: "I tuoi indirizzi" },
        // component: () => import("@/views/ProfileAddresses.vue"),
        // beforeEnter(to, from, next) {
        //   handleCategoryNavigation("addresses", to, from, next);
        // },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Addresses",
            meta: {
              requiresAuth: true,
              slug: ["profile/addresses", "addresses"]
            },
            component: () => import("@/views/profile/ProfileAddresses.vue")
          },
          {
            path: "manage/:addressId?",
            name: "EditAddresses",
            props: { modal: true },
            meta: { requiresAuth: true, breadCrumb: "Dettaglio indirizzo" },
            component: () => import("@/views/profile/EditAddress.vue")
          }
        ]
      },
      {
        path: "payment-method",
        name: "MyPaymentMethods",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei metodi di pagamento",
          slug: ["profile/payment-method", "payment-method"]
        },
        component: () => import("@/views/profile/MyPaymentMethods.vue")
      },
      {
        path: "spesa-self",
        name: "SpesaSelf",
        meta: {
          requiresAuth: true,
          breadCrumb: "La tua spesa veloce",
          slug: ["profile/spesa-self", "spesa-self"]
        },
        component: () => import("@/views/profile/SpesaSelf.vue")
      },
      {
        path: "melaleggo",
        name: "MelaLeggo",
        meta: {
          requiresAuth: true,
          breadCrumb: "La tua spesa veloce",
          slug: ["profile/melaleggo"]
        },
        component: () => import("@/views/profile/Melaleggo.vue")
      }
    ]
  }
];

export default routes;

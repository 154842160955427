<template>
  <v-bottom-navigation
    fixed
    dark
    class="bottom-action-bar white--text grey lighten-3"
  >
    <v-btn
      @click="action('init')"
      class="px-0"
      v-if="!initCode && currentRouteName != 'SelfscanningCompleted'"
    >
      <v-icon x-large>$barcode</v-icon>
    </v-btn>
    <v-btn @click="action('add')" class="px-0" v-if="initCode">
      <v-icon x-large>$plus</v-icon>
    </v-btn>
    <v-btn @click="action('remove')" class="px-0" v-if="initCode">
      <v-icon x-large>$delete</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  mixins: [],
  props: {
    initCode: { type: Boolean, require: true }
  },
  data() {
    return {};
  },
  methods: {
    action(value) {
      this.$emit(value);
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
};
</script>
<style lang="scss">
.v-main {
  padding-bottom: calc(160px + env(safe-area-inset-top)) !important;
}
.bottom-action-bar {
  height: $bottom-navigation-bar-height;
  color: $primary !important;
  position: fixed;
  bottom: 0;
  height: calc(
    #{$bottom-navigation-bar-height} + constant(safe-area-inset-top) + 20px
  ) !important;
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  height: calc(
    #{$bottom-navigation-bar-height} + env(safe-area-inset-top)
  ) !important;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  z-index: 5 !important;
  justify-content: space-evenly !important;
  .v-btn {
    min-width: auto !important;
    padding: 0 5px;
    font-size: 0.9rem !important;
    width: 81px;
    height: 81px !important;
    border-radius: 50% !important;
    background-color: $secondary !important;
    border: 6px solid var(--v-grey-lighten3);
    bottom: 20px;
    .v-btn__content {
      .v-icon {
        color: $white !important;
        font-size: 28px;
      }
      .v-image {
        padding: 5px;
        height: 100%;
        width: auto;
      }
    }
  }
}
.platform-ios {
  .bottom-action-bar {
    //height: $bottom-navigation-bar-height-ios !important;
    position: fixed;
    bottom: 0;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    max-height: calc(
      #{$bottom-navigation-bar-height-ios} + env(safe-area-inset-top) + 20px
    ) !important;
    padding-top: calc(env(safe-area-inset-top));
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: calc(110px + env(safe-area-inset-bottom));
    .v-icon {
      font-size: 32px !important;
    }
  }
}

.keyboard-open {
  .bottom-action-bar {
    display: none !important;
  }
}
</style>

<template>
  <div class="delivery-popup">
    <div class="date-time">
      <div class="date-selector" v-if="!selectAdress">
        <v-tabs
          hide-slider
          icons-and-text
          v-model="tab"
          center-active
          slider-size="1"
          show-arrows
          fixed-tabs
          class="days-tabs"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="timeslotDay in days"
            :key="timeslotDay.dateIso"
            @click="selectTimeslotDay(timeslotDay)"
          >
            <div
              class="time-div d-flex flex-column align-center justify-space-around"
            >
              <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
                <span class="day-number font-weight-bold">
                  OGGI
                </span>
              </template>
              <template v-else>
                <span class="day-string font-weight-bold">
                  {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
                </span>
                <span class="day-number font-weight-bold">
                  {{ $dayjs(timeslotDay.dateIso).format("DD/MM") }}
                </span>
              </template>
            </div>
          </v-tab>
        </v-tabs>
        <div
          class="text-center py-2 text-body-0 primary--text font-weight-bold"
        >
          Seleziona quando ritirare la tua spesa
        </div>
        <v-tabs-items v-model="tab">
          <v-tab-item
            class="timeslot-container"
            v-for="timeslotDay in days"
            :key="timeslotDay.dateIso"
          >
            <v-row dense v-if="timeslotDay.timeslots.length > 0" no-gutters>
              <v-col
                cols="4"
                sm="3"
                md="2"
                lg="3"
                v-for="timeslot in timeslotDay.timeslots"
                :key="timeslot.timeslotId"
              >
                <v-card class="timeslot-card" flat>
                  <div
                    v-on:click="selectTimeslot(timeslot)"
                    class="timeslot-button d-flex justify-center"
                    :class="[
                      timeslot.status,
                      { 'under-lead-time': timeslot.underLeadTime },
                      { 'under-lock-day': timeslot.underLockDay },
                      { 'under-lock-cutoff': timeslot.underLockCutoff }
                    ]"
                  >
                    <span class="lock-icons">
                      <v-icon
                        v-if="
                          timeslot.underLeadTime ||
                            timeslot.underLockDay ||
                            timeslot.underLockCutoff
                        "
                        x-small
                      >
                        $clock
                      </v-icon>
                    </span>
                    <span class="timeslot-time"
                      >{{ timeslot.beginTime }} - {{ timeslot.endTime }}</span
                    >
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <p v-else>
              <span
                class="d-block pa-3 secondary text-uppercase font-weight-bold text-center"
                >{{ $t("timeslots.noSlots") }}</span
              ><br />
              <span
                class="d-block pa-3 error--text secondary text-uppercase font-weight-bold text-center"
                >{{ $t("timeslots.noSlotsSuggestion") }}</span
              >
            </p>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div v-else class="select-address">
        <div class="section-title">Seleziona un indirizzo per la consegna</div>
        <v-list-item
          v-for="address in addresses"
          :key="address.addressId"
          @click="setAddress(address)"
          class="px-2 adress-list"
        >
          <v-list-item-content>
            <v-list-item-title
              class="primary--text font-weight-bold text-caption mb-1"
            >
              {{ address.addressName }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="primary--text text-caption text-sm-body-2"
            >
              {{ address.address1 }} {{ address.addressNumber }}
              {{ address.city }} {{ address.city }} {{ address.province }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>
  </div>
</template>
<script>
//import tigrosCustomService from '@/service/tigrosCustomService';
import DeliveryService from "~/service/deliveryService";
export default {
  name: "DeliverySSC",
  props: {
    popup: { type: Object, required: true }
  },
  data() {
    return {
      days: {},
      selectedTimeslotDay: {},
      selectedTime: null,
      rangeTab: 0,
      tab: 0,
      selectAdress: false,
      addresses: [],
      selectedAdress: {}
    };
  },
  methods: {
    submit() {
      this.$emit("submit", false);
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    async selectTimeslot(timeslot) {
      if (timeslot.active > 0) {
        this.selectedTime = timeslot;
        this.selectAdress = true;
        // tigrosCustomService.timeslotSelect();
      }
    },
    async fetchTimeslots() {
      this.days = await DeliveryService.getTimeslotList(false);
      //this.days = await tigrosCustomService.getTimelost();
    },
    async getAdress() {
      await DeliveryService.getWarehousesList("home").then(data => {
        this.addresses = data.addresses;
      });
    },
    setAddress(address) {
      console.log(address);
      this.selectedAdress = address;
      this.$emit("submit", {
        timeDate: this.selectedTime,
        address: this.selectedAdress
      });
    }
  },
  mounted() {
    console.log("PRODUCT", this.popup.product);
    this.fetchTimeslots();
    this.getAdress();
  }
};
</script>
<style lang="scss">
.delivery-popup {
  .date-time {
    .date-selector {
      .days-tabs {
        background-color: var(--v-grey-lighten2);
        height: 70px;
        .v-tabs-bar {
          background: transparent !important;
        }
      }
    }
    .timeslot-button {
      border-radius: 5px;
      border: 1px solid #e5e5e5;
      margin: 3px;
      font-size: 15px;
      font-weight: normal;
      pointer-events: auto;
      cursor: pointer;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px 7px;
      position: relative;
      color: $text-color;

      .timeslot-time {
        font-size: 16px;
      }
    }
    .empty {
      color: var(--v-default-darken2);
      background-color: var(--v-success-base);
      border: 1px solid #e9e9e9;
    }
    .intermediate {
      color: var(--v-default-darken2);
      background-color: var(--v-secondary-base);
      &.selected {
        border-color: $primary;
      }
    }
    .full {
      color: white;
      background: repeating-linear-gradient(
        135deg,
        #fcc,
        #fcc 4px,
        #fff 4px,
        #fff 8px
      );
      &.selected {
        border-color: $primary;
        background-color: $primary;
        color: $primary;
      }
      color: #182983;
    }
  }
  .select-address {
    .section-title {
      background-color: var(--v-grey-lighten2);
      height: 72px;
      font-size: 24px;
      font-weight: bold;
    }
    .adress-list {
      border-bottom: 1px solid var(--v-grey-lighten2);
    }
  }
}
</style>

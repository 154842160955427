let routes = [
  {
    path: "/selfscanning",
    meta: {
      requiresAuth: true,
      site: "selfscanning",
      skipCategory: true
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "HomeSSC",
        meta: {
          requiresAuth: true,
          site: "selfscanning",
          skipCategory: true
        },
        component: () => import("@/views/selfscanning/Home.vue")
      },
      {
        path: "cart-recap",
        name: "CartRecap",
        meta: {
          requiresAuth: true,
          site: "selfscanning",
          skipCategory: true
        },
        component: () => import("@/views/selfscanning/CartRecapPage.vue")
      },
      {
        path: "complete",
        name: "SelfscanningCompleted",
        meta: {
          requiresAuth: true,
          site: "selfscanning",
          skipCategory: true
        },
        component: () =>
          import("@/views/selfscanning/SelfscanningCompleted.vue")
      }
    ]
  },
  {
    path: "/searchSSC",
    name: "SelfscanningSearch",
    meta: {
      requiresAuth: true,
      site: "selfscanning",
      skipCategory: true
    },
    props: route => ({
      query: route.params,
      parent_category_id: route.params.parent_category_id,
      filter: route.params.filter,
      mode: "search"
    }),
    component: () => import("@/views/selfscanning/Search.vue")
  },
  {
    path: "/selfscanning/sscProduct/:slug",
    name: "SscProduct",
    component: () => import("@/views/catalog/Product.vue"),
    props: route => ({
      slug: route.params.slug
    }),
    meta: {
      keepAlive: true,
      site: "selfscanning",
      product: true
    }
  }
];

export default routes;

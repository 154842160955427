<template>
  <v-list class="category-tree ssc-navigation-ssc">
    <v-list-item class="hidden-md-and-up">
      <v-list-item-title class="primary--text text-h2">{{
        $t("selfScanning.navigationDrawer.menu")
      }}</v-list-item-title>
      <v-list-item-action @click.prevent.stop="drawerLeft = !drawerLeft">
        <v-icon>$close</v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-list-item exact @click="manualInput">
      <v-list-item-title class="primary--text font-weight-bold">
        {{ $t("selfScanning.navigationDrawer.manualInput") }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item exact :to="{ name: 'SelfscanningSearch' }">
      <v-list-item-title class="primary--text font-weight-bold text-none">
        {{ $t("selfScanning.navigationDrawer.search") }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item exact v-if="initCode" @click="importList">
      <v-list-item-title class="primary--text font-weight-bold">
        {{ $t("selfScanning.navigationDrawer.importList") }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item exact @click="abort" v-if="initCode">
      <v-list-item-title class="primary--text font-weight-bold">
        {{ $t("selfScanning.navigationDrawer.cancelOrder") }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item exact v-if="initCode" @click="terminate">
      <v-list-item-title class="primary--text font-weight-bold">
        {{ $t("selfScanning.navigationDrawer.finishOrder") }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item exact :to="'/shop'">
      <v-list-item-title class="primary--text font-weight-bold text-none">
        {{ $t("selfScanning.navigationDrawer.exit") }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<style lang="scss">
.ssc-navigation-ssc {
  .v-list-item {
    border-bottom: 1px solid var(--v-grey-lighten2);
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "NavigationDrawerSSC",
  mixins: [clickHandler],
  data() {
    return {
      siteLinks: []
    };
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft
    }),
    ...mapGetters({
      cartSSC: "custom/getCartSSC"
    }),
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    initCode() {
      return Object.keys(this.cartSSC).length != 0;
    }
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft"
    }),
    toggleDrawer() {
      this.drawerLeft = !this.drawerLeft;
    },
    manualInput() {
      global.EventBus.$emit("manualInputSSC");
      this.toggleDrawer();
    },
    abort() {
      global.EventBus.$emit("abortSSC");
      this.toggleDrawer();
    },
    importList() {
      global.EventBus.$emit("importListSSC");
      this.toggleDrawer();
    },
    terminate() {
      global.EventBus.$emit("terminateSSC");
      this.toggleDrawer();
    }
  },
  created() {}
};
</script>

<template>
  <div class="cart-recap">
    <div class="info-section">
      <div class="font-weight-black">
        {{ $t("selfScanning.recap.products") }}
      </div>
      <div>{{ cartSSC.totalItems }}</div>
    </div>
    <div class="info-section primary--text text-body-0">
      <div class="font-weight-black">
        {{ $t("selfScanning.recap.total") }}
      </div>
      <div class="font-weight-black">
        {{ $n(cartSSC.grossTotal, "currency") }}
      </div>
    </div>
    <div class="info-section">
      <div class="font-weight-black">
        {{ $t("selfScanning.recap.discounts") }}
      </div>
      <div>{{ $n(cartSSC.totalDiscount, "currency") }}</div>
    </div>
  </div>
</template>
<style lang="scss">
.cart-recap {
  position: fixed;
  width: 100%;
  height: 52px;
  background-color: var(--v-grey-lighten3);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  z-index: 2;
  .info-section {
    width: 100%;
    height: 100%;
    border-right: 1px solid var(--v-grey-lighten1);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .info-section:last-child {
    border-right: none;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CartRecap",
  mixins: [],
  props: {},
  computed: {
    ...mapGetters({
      cartSSC: "custom/getCartSSC"
    })
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

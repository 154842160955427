<template>
  <div>
    <CartRecap
      v-if="initCode && $route.name != 'CartRecap' && selfscanningPages"
    />
    <v-container
      class="ssc-section"
      :class="{
        'shoping-started':
          initCode && $route.name != 'CartRecap' && selfscanningPages
      }"
    >
      <!-- <div v-if="!initCode" class="text-center">
        <h1>
          Scansiona il qrcode di inizio spesa per iniziare la tua spesa self
        </h1>
      </div> -->
      <router-view></router-view>
    </v-container>
    <BottomActionBar
      v-if="selfscanningPages"
      :initCode="initCode"
      @init="init"
      @add="scan('add')"
      @remove="scan('remove')"
    />
  </div>
</template>
<style lang="scss" scoped>
.ssc-section.shoping-started {
  padding-top: 62px;
}
</style>
<script>
import BottomActionBar from "@/components/selfscanning/BottomActionBar.vue";
import SelfscanningService from "~/service/selfscanningService";
import ListService from "@/commons/service/listService";
import { mapActions, mapGetters } from "vuex";
//import SelfscanningCompleted from "@/components/selfscanning/SelfscanningCompleted.vue";
import CartRecap from "@/components/selfscanning/CartRecap.vue";
import ManualInput from "@/components/selfscanning/ManualInput.vue";
import PromoProduct from "@/components/selfscanning/PromoProduct.vue";
import SelfscanningListSelector from "@/components/selfscanning/SelfscanningListSelector.vue";
import Delivery from "@/components/selfscanning/Delivery.vue";
export default {
  name: "Selfscanning",
  mixins: [],
  components: { BottomActionBar, CartRecap },
  data() {
    return {
      STATUS_COMPLETED: 2,
      STATUS_NEED_MANUAL_INPUT: 3,
      selfCart: null,
      inputData: {}
      //initCode: false
    };
  },
  computed: {
    ...mapGetters({
      cartSSC: "custom/getCartSSC"
    }),
    initCode() {
      return Object.keys(this.cartSSC).length != 0;
    },
    selfscanningPages() {
      return this.$route.path.startsWith("/selfscanning");
    }
  },
  methods: {
    ...mapActions({
      loadCartSSC: "custom/loadCartSSC",
      updateCartSSC: "custom/updateCartSSC",
      emptyCartSSC: "custom/emptyCartSSC"
    }),
    async checkStatus() {
      this.loadCartSSC();
    },
    updateSelfCart(selfCart) {
      this.updateCartSSC(selfCart);
    },

    doUpdateSelfCart(selfCart) {
      this.selfCart = selfCart;
    },
    init() {
      this.scan("init");
    },
    scan(actionStr, callback) {
      if (window.cordova && window.cordova.plugins.barcodeScanner) {
        try {
          this.loading = true;
          // eslint-disable-next-line no-undef
          cordova.plugins.barcodeScanner.scan(
            barcodeData => {
              if (barcodeData) {
                this.loading = false;
                if (
                  barcodeData &&
                  !barcodeData.cancelled &&
                  barcodeData.text.length
                ) {
                  this.handleScan(barcodeData.text, actionStr, callback);
                }
              }
            },
            error => {
              this.loading = false;
              console.error("Scanning failed", error);
            },
            {
              showFlipCameraButton: true,
              showTorchButton: true,
              resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
              disableSuccessBeep: false
            }
          );
        } catch (err) {
          console.log(err);
        }
      } else {
        var code = prompt("Inserisci EAN", "");
        if (code) {
          this.handleScan(code, actionStr, callback);
        }
      }
    },
    async handleScan(code, action, callback, errorCallback, additionalParams) {
      var selfScanningFunction = null;

      switch (action) {
        case "init":
          selfScanningFunction = SelfscanningService.init;
          break;
        case "add":
          selfScanningFunction = SelfscanningService.addProduct;
          break;
        case "remove":
          selfScanningFunction = SelfscanningService.deleteProduct;
          break;
        case "terminate":
          selfScanningFunction = SelfscanningService.terminate;
          break;
        case "abort":
          selfScanningFunction = SelfscanningService.abort;
          break;
      }

      if (selfScanningFunction) {
        if (selfScanningFunction == SelfscanningService.addProduct) {
          let resultFunc = await selfScanningFunction(
            code,
            additionalParams ? additionalParams : 1
          );
          if (
            resultFunc.data.response.status == 2 &&
            selfScanningFunction == SelfscanningService.terminate
          ) {
            this.emptyCartSSC();
            this.$router.push({
              name: "SelfscanningCompleted",
              query: { orderId: resultFunc.data.data.orderId }
            });
          }
          if (code == "8008343200851") {
            let popupConfig = {
              title: this.$t("selfScanning.promoProduct.promo"),
              product: "7358965_passata-in-bottiglia-mutti",
              type: "promo",
              quantity: additionalParams ? additionalParams : 1
            };
            this.$dialog.show(PromoProduct, {
              waitForResult: true,
              popup: popupConfig
            });
          }
          if (code == "8056518900075") {
            let popupConfig = {
              title: this.$t("selfScanning.promoProduct.youUsuallyBuy"),
              product: "2509089",
              type: "promoCorsia",
              quantity: additionalParams ? additionalParams : 1
            };
            setTimeout(() => {
              this.$dialog.show(PromoProduct, {
                waitForResult: true,
                popup: popupConfig
              });
            }, 5000);
          }
          if (code == "8015962000021") {
            let popupConfig = {
              title: this.$t("selfScanning.promoProduct.promo"),
              product: "8015962000021",
              quantity: additionalParams ? additionalParams : 1
            };
            let res = await this.$dialog.show(Delivery, {
              waitForResult: true,
              popup: popupConfig,
              fullscreen: true,
              persistent: true
            });
            if (res) {
              console.log(res);
            }
          }
          this.updateSelfCart(resultFunc.data.data);
        } else {
          var _this = this;
          let resultFunc = await selfScanningFunction(code, additionalParams);
          console.log("resultFunc.data", resultFunc.data);
          if (
            resultFunc.data.response.status == 2 &&
            selfScanningFunction == SelfscanningService.terminate
          ) {
            this.emptyCartSSC();
            this.$router.push({
              name: "SelfscanningCompleted",
              query: { orderId: resultFunc.data.data.orderId }
            });
          } else if (selfScanningFunction != SelfscanningService.abort) {
            _this.updateSelfCart(resultFunc.data.data);
          }
          // this.checkStatus();
        }

        if (callback) {
          callback();
        } else {
          if (errorCallback) {
            errorCallback();
          }
        }
      }
    },
    async manualInput() {
      var popupConfig = {
        title: this.$t("selfScanning.manualInput.title"),
        type: this.initCode ? "add" : "init"
      };
      let res = await this.$dialog.show(ManualInput, {
        waitForResult: true,
        popup: popupConfig
      });
      if (res) {
        this.handleScan(res.code, res.action, null, null, res.q);
      }
    },
    terminate() {
      var _this = this;
      if (this.cartSSC && this.cartSSC.totalItems > 0) {
        this.showRequest(
          this.$t("selfScanning.endShopping.title"),
          this.$t("selfScanning.endShopping.description"),
          function() {
            _this.scan("terminate");
          },
          function() {}
        );
      }
    },
    abort() {
      var _this = this;
      this.showRequest(
        this.$t("selfScanning.cancelShopping.title"),
        this.$t("selfScanning.cancelShopping.description"),
        () => {
          _this.scan("abort", function() {
            _this.$router.push({
              name: "Home",
              path: "/"
            });
            _this.emptyCartSSC();
          });
        },
        function() {}
      );
    },
    async importList() {
      let personalLists = await ListService.getLists();

      var popupConfig = {
        title: this.$t("selfScanning.selectListToImport"),
        lists: personalLists
      };
      await this.$dialog.show(SelfscanningListSelector, {
        popup: popupConfig
      });
    },
    async showRequest(title, message, yesFunction, noFunction) {
      let res = await this.$dialog.confirm({
        text: message
      });
      if (res) {
        console.log(res);
        yesFunction();
      } else {
        console.log(res);
        noFunction();
      }
    }
  },
  mounted() {
    this.checkStatus();
    global.EventBus.$on("manualInputSSC", () => {
      this.manualInput();
    });
    global.EventBus.$on("abortSSC", () => {
      this.abort();
    });
    global.EventBus.$on("importListSSC", () => {
      this.importList();
    });
    global.EventBus.$on("terminateSSC", () => {
      this.terminate();
    });
  }
};
</script>

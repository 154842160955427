var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"popup-card promo-input-popup"},[(_vm.popup.title)?_c('v-card-title',{staticClass:"popup-title justify-center error--text text-uppercase"},[_vm._v(_vm._s(_vm.popup.title)),_c('span',{staticClass:"title-box"}),_c('span',{staticClass:"subtitle-box"})]):_vm._e(),_c('v-card-title',{staticClass:"popup-title justify-center"},[_vm._v(_vm._s(_vm.popup.type == "promo" ? "Passata in bottiglia" : "SHAMPOO CAMOMILLA")),_c('span',{staticClass:"title-box"}),_c('span',{staticClass:"subtitle-box"})]),_c('v-card-title',{staticClass:"popup-title justify-center"},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.popup.type == 'promo'
          ? `<span class='text-decoration-line-through'>€3,09</span><span><b class='error--text'> €1,99</b></span>`
          : '<span>€6.59</span>'
      )}})]),_c('div',{staticClass:"d-flex justify-center"},[_c('img',{attrs:{"onerror":"this.onerror=null;this.src='/no-icon.png'","src":_vm.popup.type == 'promo'
          ? 'img_layout/passata_small.png'
          : 'img_layout/shampoo.jpg',"alt":_vm.promoProduct.name,"width":"200"}})]),_c('v-card-actions',{staticClass:"justify-space-between"},[_c('v-btn',{staticClass:"action-btn",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t("selfScanning.promoProduct.cancel")))]),_c('v-btn',{staticClass:"action-btn",attrs:{"color":"secondary","depressed":""},on:{"click":function($event){return _vm.addCode()}}},[_vm._v(_vm._s(_vm.$t("selfScanning.promoProduct.confirm")))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
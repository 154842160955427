<template>
  <v-card class="cart-summary px-3 text-right" flat v-if="cart.cartId > 0">
    <v-card-text class="default--text">
      <!-- <div class="d-flex justify-end align-top font-weight-bold">
        <span>{{ $t("cartSummary.totalPrice") }}</span>
        <span class="value">
          {{ $n(cart.totalPrice, "currency") }}
        </span>
      </div> -->
      <div class="d-flex justify-end align-top font-weight-bold text-body-1">
        <span>{{ $t("cartSummary.totalPriceVariable") }}</span>
        <span class="value">{{ $n(cart.totalPriceVariable, "currency") }}</span>
      </div>
      <div class="d-flex justify-end">
        <div
          class="text-h3 d-flex justify-end align-center font-weight-bold text-uppercase"
        >
          <span>{{ $t("cartSummary.grossTotal") }}</span
          ><span class="value">{{ $n(cart.totalPrice, "currency") }}</span>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-row no-gutters>
        <v-col :cols="cart.totalItems > 0 ? 5 : 12" class="pa-1">
          <!-- <div>
            <v-btn
              x-large
              color="secondary"
              class="primary--text"
              depressed
              min-width="95%"
              v-if="!full"
              :loading="isSelecting"
              @click="onButtonClick"
            >
              <span color="st" class="default--text"> {{ buttonText }}</span>
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="onFileChanged"
            />
          </div>-->
          <div class="file-upload-wrapper" style="position: relative;">
            <v-file-input
              hide-details
              outlined
              @change="uploadFile"
              prepend-icon=""
              style="background-color: #ffcc33; color: #565657; border: 0px"
            ></v-file-input>
            <span
              class="font-weight-bold"
              style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);pointer-events: none;"
              >{{ fileName }}</span
            >
          </div>

          <!-- <v-btn
            x-large
            color="secondary"
            class="primary--text"
            depressed
            min-width="95%"
            v-if="!full"
          >
            <v-file-input @change="uploadFile" prepend-icon=""></v-file-input>
            <span>{{ fileName }}</span>
          </v-btn>-->
        </v-col>
        <v-col cols="7" class="pa-1" v-if="cart.totalItems > 0">
          <v-btn
            class="checkout-btn"
            to="/checkout"
            color="primary"
            min-width="95%"
            x-large
            depressed
            >{{ $t("cart.button.goToPayment") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  <v-card v-else>{{ $t("no-cart") }}</v-card>
</template>
<style lang="scss">
.cart-summary {
  .value {
    min-width: 150px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-card__actions {
      padding: 8px 0;
    }
  }
  .file-upload-wrapper .v-file-input .v-file-input__text {
    display: none !important;
  }
}
</style>
<script>
import FastPayment from "@/components/cart/FastPayment.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "CartSummary",
  props: ["full"],
  data() {
    return {
      fileName: "Upload PDF",
      ws1Data: [
        {
          cartItemStatusId: 1,
          quantity: 2,
          weight: 0,
          unitPrice: 487.9,
          grossTotal: 487.9,
          discount: 0,
          promoPoints: 0,
          itemId: 181573921,
          deliveryWarehouseId: null,
          cartItemInfo: {},
          product: {
            available: 10000,
            productId: 148000015,
            codInt: "148000015",
            codVar: "1",
            code: "148000015",
            codeVariant: "1",
            name:
              "Außengabel mit 2,00 Stk 50,11 50,11 100,22 1041051 Klemmschraube, AGKF W2200 1 3/8-6 20.10.02",
            shortDescr: "Außengabel mit 2,00 Stk 50",
            description: "Außengabel mit 2,00 Stk 50",
            pubState: 1,
            ivaCategory: {
              ivaCategoryId: 212,
              code: "4-N",
              descr: "",
              ivaPct: "0.04"
            },
            logisticPackagingItems: 1,
            price: 50.11,
            priceId: 45504803,
            priceListName: "Base",
            priceListId: -1,
            priceDisplay: 50.11,
            priceUnitDisplay: "pz",
            slug: "148000015_walterscheid-pto-driveshaft-w-2400-1800065",
            categoryId: 148000613,
            mediaURL:
              "https://www.tigros.it/photo2/testx/wal-ecoline-side-170.jpg",
            mediaURLMedium:
              "https://www.tigros.it/photo2/testx/wal-ecoline-side-360.jpg",
            dayLock: "",
            vendor: {
              vendorId: 0
            },
            productClasses: [],
            selectOptions: [],
            productInfos: {
              MINACQ: "1",
              WEB_ENABLED: "1",
              CHECKED: "true",
              TIPOLOGIA: "Pezzo"
            },
            hasDetails: false
          }
        },
        {
          cartItemStatusId: 1,
          quantity: 3,
          weight: 0,
          unitPrice: 600,
          grossTotal: 600,
          discount: 0,
          promoPoints: 0,
          itemId: 181539921,
          deliveryWarehouseId: null,
          cartItemInfo: {},
          product: {
            available: 10000,
            productId: 148000021,
            codInt: "148000021",
            codVar: "1",
            code: "148000021",
            codeVariant: "1",
            name: "Außengabel test 2",
            shortDescr: "Außengabel test 2",
            description: "Außengabel test 2",
            pubState: 1,
            ivaCategory: {
              ivaCategoryId: 212,
              code: "4-N",
              descr: "",
              ivaPct: "0.04"
            },
            logisticPackagingItems: 1,
            price: 50.11,
            priceId: 45504703,
            priceListName: "Base",
            priceListId: -1,
            priceDisplay: 50.11,
            priceUnitDisplay: "pz",
            slug: "148000015_walterscheid-pto-driveshaft-w-2400-1800065",
            categoryId: 148000613,
            mediaURL:
              "https://www.tigros.it/photo2/testx/wal-ecoline-side-170.jpg",
            mediaURLMedium:
              "https://www.tigros.it/photo2/testx/wal-ecoline-side-360.jpg",
            dayLock: "",
            vendor: {
              vendorId: 0
            },
            productClasses: [],
            selectOptions: [],
            productInfos: {
              MINACQ: "1",
              WEB_ENABLED: "1",
              CHECKED: "true",
              TIPOLOGIA: "Pezzo"
            },
            hasDetails: false
          }
        }
      ],
      ws2Data: [
        {
          cartItemStatusId: 1,
          quantity: 2,
          weight: 0,
          unitPrice: 487.9,
          grossTotal: 487.9,
          discount: 0,
          promoPoints: 0,
          itemId: 181373921,
          deliveryWarehouseId: null,
          cartItemInfo: {},
          product: {
            available: 10000,
            productId: 148000015,
            codInt: "148000015",
            codVar: "1",
            code: "148000015",
            codeVariant: "1",
            name:
              "Außengabel mit 2,00 Stk 50,11 50,11 100,22 1041051 Klemmschraube, AGKF W2200 1 3/8-6 20.10.02",
            shortDescr: "Außengabel mit 2,00 Stk 50",
            description: "Außengabel mit 2,00 Stk 50",
            pubState: 1,
            ivaCategory: {
              ivaCategoryId: 212,
              code: "4-N",
              descr: "",
              ivaPct: "0.04"
            },
            logisticPackagingItems: 1,
            price: 50.11,
            priceId: 45504803,
            priceListName: "Base",
            priceListId: -1,
            priceDisplay: 50.11,
            priceUnitDisplay: "pz",
            slug: "148000015_walterscheid-pto-driveshaft-w-2400-1800065",
            categoryId: 148000613,
            mediaURL:
              "https://www.tigros.it/photo2/testx/wal-ecoline-side-170.jpg",
            mediaURLMedium:
              "https://www.tigros.it/photo2/testx/wal-ecoline-side-360.jpg",
            dayLock: "",
            vendor: {
              vendorId: 0
            },
            productClasses: [],
            selectOptions: [],
            productInfos: {
              MINACQ: "1",
              WEB_ENABLED: "1",
              CHECKED: "true",
              TIPOLOGIA: "Pezzo"
            },
            hasDetails: false
          }
        },
        {
          cartItemStatusId: 1,
          quantity: 4,
          weight: 0,
          unitPrice: 487.9,
          grossTotal: 487.9,
          discount: 0,
          promoPoints: 0,
          itemId: 181571123,
          deliveryWarehouseId: null,
          cartItemInfo: {},
          product: {
            available: 10000,
            productId: 148000015,
            codInt: "148000015",
            codVar: "1",
            code: "148000015",
            codeVariant: "1",
            name:
              "Außengabel mit 2,00 Stk 50,11 50,11 100,22 1041051 Klemmschraube, AGKF W2200 1 3/8-6 20.10.02",
            shortDescr: "Außengabel mit 2,00 Stk 50",
            description: "Außengabel mit 2,00 Stk 50",
            pubState: 1,
            ivaCategory: {
              ivaCategoryId: 212,
              code: "4-N",
              descr: "",
              ivaPct: "0.04"
            },
            logisticPackagingItems: 1,
            price: 50.11,
            priceId: 45504803,
            priceListName: "Base",
            priceListId: -1,
            priceDisplay: 50.11,
            priceUnitDisplay: "pz",
            slug: "148000015_walterscheid-pto-driveshaft-w-2400-1800065",
            categoryId: 148000613,
            mediaURL:
              "https://www.tigros.it/photo2/testx/wal-ecoline-side-170.jpg",
            mediaURLMedium:
              "https://www.tigros.it/photo2/testx/wal-ecoline-side-360.jpg",
            dayLock: "",
            vendor: {
              vendorId: 0
            },
            productClasses: [],
            selectOptions: [],
            productInfos: {
              MINACQ: "1",
              WEB_ENABLED: "1",
              CHECKED: "true",
              TIPOLOGIA: "Pezzo"
            },
            hasDetails: false
          }
        }
      ]
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart",
      updatedDrawerRight: "app/updatedDrawerRight",
      removeGiftCode: "cart/removeGiftCode",
      addProduct: "cart/addProduct"
    }),
    uploadFile(file) {
      if (file) {
        this.fileName = file.name;
      } else {
        this.fileName = "Upload PDF";
      }
      if (file && file.name == "ws1.pdf") {
        // If you need to add complete fake datas, uncomment global emit and comment this.addProduct
        //  global.EventBus.$emit("fakeAddProducts", this.ws1Data);
        for (const product of this.ws1Data) {
          console.log("tesdtt", product);
          setTimeout(() => {
            this.addProduct(product);
          }, 1000);
        }
      }
      if (file && file.name == "ws2.pdf") {
        // If you need to add complete fake datas, uncomment global emit and comment this.addProduct
        //  global.EventBus.$emit("fakeAddProducts", this.ws1Data);

        for (const product of this.ws1Data) {
          console.log("tesdtt", product);
          setTimeout(() => {
            this.addProduct(product);
          }, 1000);
        }
      }
    },
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    async fastPayment() {
      let _this = this;
      let res = await _this.$dialog.show(FastPayment, {
        waitForResult: true,
        width: 500
      });
      if (res) {
        // let items = [];
        // forEachCartItem(_this.cart, function(item) {
        //   items.push(item);
        // });
        // await ListService.addProductsToList(res.listId, items);
      }
    },
    closeDrawer() {
      this.updatedDrawerRight(false);
    },
    removeCode(giftCodeId) {
      this.removeGiftCode(giftCodeId);
    },
    changeService() {},
    changeTimeslot() {},
    changeDelivery() {}
  }
};
</script>
